import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';

import { filledStyles } from './filledStyles';
import { filledTransparentStyles } from './filledTransparentStyles';
import { outlineStyles } from './outlineStyles';
import { outlineTransparentStyles } from './outlineTransparentStyles';

const Container = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
  user-select: none;

  ${filledStyles}
  ${filledTransparentStyles}
  ${outlineStyles}
  ${outlineTransparentStyles}
`;

export interface ButtonStylesProps
  extends Pick<React.HTMLAttributes<HTMLSpanElement>, 'className' | 'onClick'> {
  buttonStyle?: 'filled' | 'outline';
  color?: 'primary' | 'blue' | 'dark' | 'neutral' | 'light' | 'green' | 'red' | 'purple';
  colorStyle?: 'bold' | 'matte' | 'ghost';
  content?: React.ReactNode;
  transparent?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

export const ButtonStyles = (props: ButtonStylesProps) => {
  const {
    className,
    buttonStyle = 'filled',
    color,
    colorStyle,
    content,
    onClick,
    transparent = false,
    disabled = false,
    loading = false,
  } = props;

  return (
    <Container
      className={cl(
        'button-styles',
        buttonStyle,
        `color-${color}`,
        `
        color-style-${colorStyle}`,
        { transparent, disabled, loading },
        className
      )}
      onClick={(e) => !disabled && onClick?.(e)}
    >
      {content}
    </Container>
  );
};
